import { Injectable } from "@angular/core";
import { HttpService } from "../core/service/http.service";
import { BehaviorSubject, Subject } from "rxjs";
@Injectable({
  providedIn: "root",
})
export class DashboardService {
  constructor(private http: HttpService) {}

  public PASSWORD_REGEXP =
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*\W)[a-zA-Z0-9\S]{8,15}$/;

  // data pass through observable

  private dataShare$ = new BehaviorSubject<any>({});
  selectedProduct$ = this.dataShare$.asObservable();

  private dataSharesAlert$ = new BehaviorSubject<any>({});
  selectedProducts$ = this.dataSharesAlert$.asObservable();

  private dataAlertShare$ = new BehaviorSubject<any>({});
  alerts$ = this.dataAlertShare$.asObservable();

  private dataShares$ = new BehaviorSubject<any>({});
  sendMsg$ = this.dataShares$.asObservable();

  private dataSend = new BehaviorSubject<any>({});
  dataSend$ = this.dataSend.asObservable();

  private dataSendId = new BehaviorSubject<any>({});
  dataSendId$ = this.dataSendId.asObservable();

  private dataSendId1 = new BehaviorSubject<any>({});
  dataSendId1$ = this.dataSendId.asObservable();

  private methodCallTrigger = new BehaviorSubject<any>({});
  methodCallTrigger$ = this.methodCallTrigger.asObservable();

  private deleteMethodCallTrigger = new BehaviorSubject<any>({});
  deleteMethodCallTrigger$ = this.deleteMethodCallTrigger.asObservable();

  private listCallTrigger = new BehaviorSubject<any>({});
  listCallTrigger$ = this.listCallTrigger.asObservable();

  private dataTransform$ = new BehaviorSubject<any>({});
  dataSendToReceivedComp$ = this.dataTransform$.asObservable();

  private faq = new BehaviorSubject<any>({});
  faq$ = this.faq.asObservable();

  private headerValue = new BehaviorSubject<any>({});
  headerValuation$ = this.headerValue.asObservable();

  private backPage = new BehaviorSubject<any>(null);
  backPage$ = this.backPage.asObservable();
  updateConsumerId = new BehaviorSubject(null);

  // api call methods

  GetUsersData(userData) {
    return this.http.postAnonymous("User/List", userData);
  }
  GetAllStatusWiseConsumerUnderCoach(userData) {
    return this.http.postAnonymous(
      "User/GetAllStatusWiseConsumerUnderCoach",
      userData
    );
  }
  GetDeviceList(userData) {
    return this.http.postAnonymous("Device/List", userData);
  }
  getAllRepositoryStat(userData) {
    return this.http.post("Device/GetRepositoryStat", userData);
  }

  getAllRepositoryStatDayWise(userData) {
    return this.http.post("Device/GetRepositoryStatDayWise", userData);
  }
  getUserRepositoryStatDayWise(userData) {
    return this.http.post("Device/GetUserRepositoryStatDayWise", userData);
  }
  getSessionStat(userData) {
    return this.http.post("Device/GetSessionStat", userData);
  }
  getUserSession(userData) {
    return this.http.post("Device/GetFitnessSessionDescription", userData);
  }
  getUserSessionStat(userData) {
    return this.http.post("Device/GetUserSessionStat", userData);
  }
  getSessionStatByDays(userData) {
    return this.http.post("Device/GetSessionStatDayWise", userData);
  }
  getUserSessionStatByDays(userData) {
    return this.http.post("Device/GetUserSessionStatDayWise", userData);
  }
  getUserRepositoryStat(userData) {
    return this.http.post("Device/GetUserRepositoryStat", userData);
  }

  getAllMetabolicStat(userData) {
    return this.http.post("Device/GetMetabolicStat", userData);
  }
  getUserMetabolicStat(userData) {
    return this.http.post("Device/GetUserMetabolicStat", userData);
  }
  getUserMetabolicStatByDays(userData) {
    return this.http.post("Device/GetUserMetabolicStatDayWise", userData);
  }
  getSessionReadingMetaData(searchData) {
    return this.http.post("Device/GetSessionReadingMetaData", searchData);
  }

  getGraphBySessionId(searchData) {
    return this.http.post(
      "Device/GetSessionReadingCalculationsWeb",
      searchData
    );
  }

  GetUserData(userData) {
    return this.http.postAnonymous("User/Get", userData);
  }

  GetSummeryData(data) {
    return this.http.post("Device/Get", data);
  }

  GetActiveDeviceData(deviceData) {
    return this.http.post("Device/GetByUserId", deviceData);
  }

  GetDeviceData(deviceData) {
    return this.http.post("Device/GetAllUsersByUserId", deviceData);
  }

  GetRegisteredData(registeredData) {
    return this.http.postAnonymous("Device/StatsData", registeredData);
  }
  GetRegisteredDatas(registeredData) {
    return this.http.postAnonymous("Device/StatsData", registeredData);
  }

  getUserStatsTotalUsers(ParamTotalUsers) {
    return this.http.postAnonymous("Device/UsersStats", ParamTotalUsers);
  }

  GetMonthwiseUsersStats(ParamhwiseUsersStats) {
    return this.http.postAnonymous("Device/UsersStats", ParamhwiseUsersStats);
  }

  GetDeviceUsage(ParamTotalUsers1) {
    return this.http.postAnonymous("Device/DeviceUsageStat", ParamTotalUsers1);
  }

  getAverageDeviceUsage(ParamhwiseUsersStats1) {
    return this.http.postAnonymous(
      "Device/DeviceUsageStat",
      ParamhwiseUsersStats1
    );
  }

  UnRegisterDevice(data: any) {
    return this.http.post("Device/UnRegister", data);
  }

  AddCoachData(userData) {
    return this.http.post("User/AddCoach", userData);
  }
  EditCoachInfo(userData) {
    return this.http.post("User/UpdateCoach", userData);
  }
  EditMemberInfo(userData) {
    return this.http.post("User/Update", userData);
  }

  AddAdminData(userData) {
    return this.http.post("User/Admin/Add", userData);
  }

  EditAdminData(userData) {
    return this.http.post("User/Admin/Update", userData);
  }

  ApprovalForCoach(userData) {
    return this.http.post("User/ApprovalForCoach", userData);
  }

  GetCoachMemberDetails(userData) {
    return this.http.post("User/GetCoachMemberDetails", userData);
  }

  GetAllRequestToAdminForCoach(userData) {
    return this.http.post("User/GetAllRequestToAdminForCoach", userData);
  }

  sendRequestAccessForConsumer(userData, url) {
    return this.http.post(url, userData);
  }

  addTeams(userData) {
    return this.http.post("User/AddTeams ", userData);
  }

  updateTeamsForConsumerByCoach(userData) {
    return this.http.post("User/RemoveAndAddTeamMembersFromTeam", userData);
  }

  getTeamsByCoachId(userData) {
    return this.http.post("User/GetTeamsByCoachId ", userData);
  }

  deleteTeamsById(userData) {
    return this.http.post("User/DeleteTeam", userData);
  }

  updateTeamsById(userData) {
    return this.http.post("User/UpdateTeam", userData);
  }
  addMembersInTeam(userData) {
    return this.http.post("User/AddMembersIntoTeam", userData);
  }

  getConsumerListForCoach(userData) {
    return this.http.post("User/GetAllConsumersUnderCoach", userData);
  }
  getConsumerListCoach(userData) {
    return this.http.post("User/GetAllConsumersUnderCoachNew", userData);
  }

  getCoachRequestForConsumer(userData) {
    return this.http.post("User/GetAllCoachRequestForConsumer", userData);
  }
  getListOfPendingCoachRequest(userData) {
    return this.http.post("User/GetPendingRequestToConsumer", userData);
  }

  revokeAccess(userData) {
    return this.http.post("User/ApprovalForCoach", userData);
  }

  GetConsumerGraphTotalDurationForDashboard(ParamTotalUsers1) {
    return this.http.postAnonymous(
      "User/GetConsumerGraphTotalDurationForDashboard ",
      ParamTotalUsers1
    );
  }

  GetConsumerGraphTotalSessionForDashboard(ParamTotalUsers1) {
    return this.http.postAnonymous(
      "User/GetConsumerGraphTotalSessionForDashboard ",
      ParamTotalUsers1
    );
  }

  revokeAccessOfCoach(userDetails: any, url: any) {
    return this.http.postAnonymous(url, userDetails);
  }

  GetAllPendingRequestToConsumer(userData) {
    return this.http.post("User/GetAllPendingRequestToConsumer", userData);
  }

  revokeAccessByConsumer(userData) {
    return this.http.post("User/RevokeDataAccessByConsumer", userData);
  }

  GetAdminData(data: any) {
    return this.http.post("User/Admin/GetAll", data);
  }

  resetPassword(data: any) {
    return this.http.post("User/ResetPassword", data);
  }

  getFilePathFromApiForAdmin(data: any) {
    return this.http.postData("User/UploadSessionLogFileForAdmin", data);
  }

  getFilePathFromAPI(data: any) {
    return this.http.postData("Device/GetSessionUrl", data);
  }

  getCountryCodeList(data: any) {
    return this.http.post("User/GetCountryCodes", data);
  }

  RegisterAsCoachData(userData) {
    return this.http.post("User/RegisterAsCoach", userData);
  }

  // data receive form sender components
  newInfo(data: any) {
    this.dataShare$.next(data);
  }

  sendAlertDeleteAccount(data: any) {
    this.dataSharesAlert$.next(data);
  }

  accessDenied(data: any) {
    this.dataShare$.next(data);
  }

  sendSuccessMessage(data: any) {
    this.dataShare$.next(data);
  }

  accessRequestConumer(data: any) {
    this.dataShares$.next(data);
  }

  sendSuccessResult(data: any) {
    this.dataTransform$.next(data);
  }
  sendUpdateAdmin(data: any) {
    this.dataTransform$.next(data);
  }

  sendActiveTabName(data: any) {
    this.dataSend.next(data);
  }
  sendUserId(data: any) {
    this.dataSendId.next(data);
  }

  sendTeamTrigger(data: any) {
    this.methodCallTrigger.next(data);
  }

  sendDeleteTrigger(data: any) {
    this.deleteMethodCallTrigger.next(data);
  }

  sendTriggerForRejectAccess(data: any) {
    this.listCallTrigger.next(data);
  }

  sendWebFaqName(data: any) {
    this.faq.next(data);
  }

  sendMobileFaqName(data: any) {
    this.faq.next(data);
  }

  sendPrivacyName(data: any) {
    this.faq.next(data);
  }

  sendTermsName(data: any) {
    this.faq.next(data);
  }

  sendHeader(data: any) {
    this.headerValue.next(data);
  }

  sendAlert(data: any) {
    this.dataAlertShare$.next(data);
  }

  // phone number validate
  ValidateNumeric(evt: any) {
    var keyCode = evt.keyCode;
    if (keyCode >= 48 && keyCode <= 57) {
      return true;
    } else {
      return false;
    }
  }

  ValidateFirstAlpha(evt: any) {
    var char = String.fromCharCode(evt.keyCode);
    var inputValue = evt.target.value;

    if (
      /^[a-zA-Z']$/.test(char) ||
      (char == " " && /^[a-zA-Z']+( [a-zA-Z']+)*$/.test(inputValue.trim()))
    ) {
      return true;
    } else {
      return false;
    }
  }

  getXaxis(numberofDays: number) {
    let days = [];
    let totalDays = numberofDays / 12;
    let roundNumber = Math.round(totalDays);
    let multipes: number = 12;

    if (numberofDays >= 6 && numberofDays <= 12) {
      roundNumber = 1;
    } else if (numberofDays <= 6) {
      multipes = 6;
      roundNumber = 1;
    }

    for (let i = 1; i <= multipes; i++) {
      days.push(roundNumber * i);
    }

    days.unshift("x");
    return days;
  }

  // y axis values for respiratory lifetime default graphs.
  getYaxisForSessions(sessionData: any, type: string) {
    let yAxis = [];
    for (let data of sessionData) {
      if (
        type === "ExhaledCo2Count" ||
        type === "ExhaledO2Count" ||
        type === "Co2ProductionCount" ||
        type === "OxygenUptakeCount" ||
        type === "RecentBreathRateCount"
      ) {
        yAxis.push(data.hasOwnProperty(type) ? data[type]?.toFixed(1) : "");
      } else if (
        type === "RerCount" ||
        type === "BreathVolumeCount" ||
        type === "PickVo2Count" ||
        type === "PickCo2Count"
      ) {
        yAxis.push(data.hasOwnProperty(type) ? data[type]?.toFixed(2) : "");
      } else if (type === "BreathCount") {
        yAxis.push(data.hasOwnProperty(type) ? data[type]?.toFixed(0) : "");
      }
    }
    return yAxis;
  }

  // y axis values for respiratory selected sessions graphs.
  getYaxisForUserSession(sessionData: any, type: string) {
    let yAxis = [];
    for (let data of sessionData) {
      if (
        type === "SV_ECO2_avg" ||
        type === "SV_EO2_avg" ||
        type === "SV_CO2_tot" ||
        type === "SV_O2_tot" ||
        type === "SV_BR_avg"
      ) {
        yAxis.push(data.hasOwnProperty(type) ? data[type]?.toFixed(1) : "");
      } else if (
        type === "SV_RER_avg" ||
        type === "SV_BV_tot" ||
        type === "SV_VCO2_peak" ||
        type === "SV_VO2_peak"
      ) {
        yAxis.push(data.hasOwnProperty(type) ? data[type]?.toFixed(2) : "");
      } else if (type === "SV_BC_tot") {
        yAxis.push(data.hasOwnProperty(type) ? data[type]?.toFixed(0) : "");
      }
    }
    return yAxis;
  }

  // default method for y - axis values print
  getYaxisForSession(sessionData: any, type: string) {
    let yAxis = [];
    for (let data of sessionData) {
      yAxis.push(data.hasOwnProperty(type) ? data[type] : "");
    }
    return yAxis;
  }

  // y axis values for metabolic lifetime default graphs.
  getYaxisForMetaSessions(sessionData: any, type: string) {
    let yAxis = [];
    for (let data of sessionData) {
      if (
        type === "Calories" ||
        type === "TotalFatBurn" ||
        type === "TotalCarbBurn" ||
        type === "RespWaterLoss" ||
        type === "Keto"
      ) {
        yAxis.push(data.hasOwnProperty(type) ? data[type]?.toFixed(1) : "");
      } else if (
        type === "CaloriesBurnRate" ||
        type === "PeakCalireBurn" ||
        type === "PeakFatBurn"
      ) {
        yAxis.push(data.hasOwnProperty(type) ? data[type]?.toFixed(2) : "");
      } else if (type === "FatBurn" || type === "CarbBurn") {
        yAxis.push(data.hasOwnProperty(type) ? data[type] : "");
      }
    }
    return yAxis;
  }

  // y axis values for metabolic selected sessions graphs.
  getYaxisForUserMetaSession(sessionData: any, type: string) {
    let yAxis = [];
    for (let data of sessionData) {
      if (
        type === "SV_CAL_tot" ||
        type === "SV_FAT_tot" ||
        type === "SV_CARB_tot" ||
        type === "SV_H2O_tot"
      ) {
        yAxis.push(data.hasOwnProperty(type) ? data[type]?.toFixed(1) : "");
      } else if (
        type === "SV_CAL_avg" ||
        type === "SV_CAL_peak" ||
        type === "SV_FAT_peak"
      ) {
        yAxis.push(data.hasOwnProperty(type) ? data[type]?.toFixed(2) : "");
      } else if (type === "SV_PCALC_avg" || type === "SV_PCALF_avg") {
        yAxis.push(data.hasOwnProperty(type) ? data[type] : "");
      }
    }
    return yAxis;
  }

  getXaxisForSessions(sessionData: any) {
    let xAxis = [];
    sessionData.forEach((item, index) => {
      item.displayIndex = index + 1; // Adding 1 to the index
      xAxis.push(item.displayIndex);
    });
    xAxis.unshift("x");
    return xAxis;
  }

  getSum(obj: any, type: string) {
    let sum = obj.reduce((accumulator, currentValue) => {
      return accumulator + Number(currentValue[type]);
    }, 0);
    return sum;
  }

  // Calculate the date six months ago
  getLastMonthDateFromToday(months: number) {
    var currentDate = new Date();
    // Calculate the date six months ago
    var sixMonthsAgo = new Date();
    sixMonthsAgo.setMonth(currentDate.getMonth() - months);
    // Format the date for display
    var formattedDate = sixMonthsAgo.toISOString().slice(0, 10);
    return formattedDate;
  }

  getXaxisDayWise(obj: any, type: string) {
    let xAxis = [];
    let multipes: number = 6;
    if (obj.length <= 6) {
      for (let i = 1; i <= multipes; i++) {
        xAxis.push(1 * i);
      }
    } else {
      for (let data of obj) {
        xAxis.push(data.hasOwnProperty(type) ? data[type] : "");
      }
    }
    xAxis.unshift("x");
    return xAxis;
  }

  fixedValues(data: any, graphName: String) {
    data.map((item) => {
      if (typeof item === "number") {
        return item?.toFixed(1); // Convert number to string with one decimal place
      } else {
        return item; // Keep strings unchanged
      }
    });
  }

  // getOtp
  getOtp(userData) {
    return this.http.postAction("User/SendOTP", userData);
  }

  deleteAccount(userData) {
    return this.http.postAction("User/Delete", userData);
  }

  getMemberSessionList(payload: any) {
    return this.http.post("User/GetAllSessionsList", payload);
  }

  restoreMemberSession(payload: any) {
    return this.http.post("User/RestoreSessions", payload);
  }
}
