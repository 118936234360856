import { Component, OnInit } from "@angular/core";
import {
  FormBuilder,
  FormGroup,
  Validators,
} from "@angular/forms";
import { NgxSpinnerService } from "ngx-spinner";
import { Router } from "@angular/router";
import { NotificationService } from "src/app/core/service/notification.service";
import { DashboardService } from "../dashboard/dashboard.service";
import { AuthService } from "@auth0/auth0-angular";
import { Constants } from "../config/constants";
@Component({
  selector: "app-member-account-delete",
  templateUrl: "./member-account-delete.component.html",
  styleUrls: ["./member-account-delete.component.scss"],
})
export class MemberAccountDeleteComponent implements OnInit {
  submitted: boolean = false;
  otp: any = 0;
  passedData: any;
  email: "";
  timeLeft: string;
  timeoutObj: any;
  otpRequired: boolean;
  timerOn: boolean = true;
  getFormData = {
    email: "",
  };
  userId = sessionStorage.getItem("UserId");
  deleteUserAccount: FormGroup;
  constants: any;
  intervaObj: any;
  emailForm: boolean = true;
  otpForm: boolean = false;
  // for otp timer
  showTime = true;
  resendOtpLink = false;

  constructor(
    private spinner: NgxSpinnerService,
    private notificationService: NotificationService,
    public dashboardService: DashboardService,
    private fb: FormBuilder,
    private authService: AuthService,
    public router: Router
  ) {
    this.constants = Constants;
    this.deleteUserAccount = this.fb.group({
      email: [
        "",
        [Validators.required, Validators.pattern(this.constants.EMAIL_REGEXP)],
      ],
    });
  }

  ngOnInit(): void {
    const dataString = sessionStorage.getItem("data"); // Retrieve data from session storage
    if (dataString) {
      this.passedData = JSON.parse(dataString);
    }
  }

  // get OTP
  getOTP() {
    if (this.deleteUserAccount.valid) {
      this.getFormData.email = this.deleteUserAccount.get("email").value;

      var data = {
        Email: this.getFormData.email,
      };
      this.spinner.show();
      this.dashboardService.getOtp(data).subscribe({
        next: (res: any) => {
          if (!res.HasErrors) {
            this.spinner.hide();

            this.emailForm = false;
            this.otpForm = true;
            this.timer(60);
            this.notificationService.showNotification(
              "snackbar-success",
              "OTP sent on registered email",
              "top",
              "center"
            );
          } else {
            this.spinner.hide();
            this.notificationService.showNotification(
              "snackbar-danger",
              res.Errors[0].Message,
              "top",
              "center"
            );
          }
        },
        error: (error) => {
          this.spinner.hide();
        },
      });
    }
  }

  // deleteAccounts
  deleteAccounts() {
    this.submitted = true;
    if (this.otp == 0 || this.otp == undefined) {
      this.otpRequired = true;
    } else {
      this.otpRequired = false;
    }
    if (!this.otpRequired) {
      this.getFormData.email = this.deleteUserAccount.get("email").value;

      var data = {
        Email: this.getFormData.email,
        OTP: this.otp,
      };
      this.spinner.show();
      this.dashboardService.deleteAccount(data).subscribe({
        next: (res: any) => {
          if (!res.HasErrors) {
            this.spinner.hide();
            this.dashboardService.sendAlertDeleteAccount("delete");
            localStorage.setItem("account-deleted", "true");

            this.intervaObj = setInterval(() => {
              this.logout();
            }, 2000);
            this.notificationService.showNotification(
              "snackbar-success",
              "Account deleted successfully!",
              "top",
              "center"
            );
          } else {
            this.spinner.hide();
            this.notificationService.showNotification(
              "snackbar-danger",
              res.Errors[0].Message,
              "top",
              "center"
            );
          }
        },
        error: (error) => {
          this.spinner.hide();
        },
      });
    }
  }

  // logout method
  logout() {
    this.authService.logout();
    sessionStorage.clear();
    localStorage.clear();
    this.router.navigate(["/authentication/login"]);
  }

  // cancel
  cancel() {
    this.emailForm = true;
    this.otpForm = false;
    clearInterval(this.timeoutObj);
    this.otpRequired = false;
  }

  //otp timer
  timer(remaining: any) {
    this.timeLeft = "";
    var m: any = Math.floor(remaining / 60);
    var s: any = remaining % 60;

    m = m < 10 ? "0" + m : m;
    s = s < 10 ? "0" + s : s;

    this.timeLeft = m + ":" + s;

    remaining -= 1;

    if (remaining >= 0 && this.timerOn) {
      this.timeoutObj = setTimeout(() => {
        this.timer(remaining);
      }, 1000);
      return;
    }

    if (!this.timerOn) {
      return;
    }
  }

  //validate otp
  onOtpChange(event: any) {
    this.otp = event;
    if (event.length == 0) {
      this.otpRequired = true;
    } else {
      this.otpRequired = false;
    }
  }

  goToLogin() {
    this.router.navigate(["/authentication/login"]);
  }
}
