import { DOCUMENT } from "@angular/common";
import {
  Component,
  Inject,
  ElementRef,
  OnInit,
  Renderer2,
  AfterViewInit,
  OnDestroy,
} from "@angular/core";
import { Router } from "@angular/router";
import { ConfigService } from "src/app/config/config.service";
import { AuthService } from "@auth0/auth0-angular";
import { RightSidebarService } from "src/app/core/service/rightsidebar.service";
import { LanguageService } from "src/app/core/service/language.service";
import { UnsubscribeOnDestroyAdapter } from "src/app/shared/UnsubscribeOnDestroyAdapter";
import { ViewProfileComponent } from "../../shared/profile/view-profile/view-profile.component";
import { MatDialog } from "@angular/material/dialog";
import { NotificationService } from "src/app/core/service/notification.service";
import { ProfileService } from "src/app/shared/profile/profile.service";
import { DashboardService } from "src/app/dashboard/dashboard.service";
import { NgxSpinnerService } from "ngx-spinner";
import { AddRequestModalComponent } from "src/app/coach-portal/add-request-modal/add-request-modal.component";
import { RejectDataAccessComponent } from "src/app/dashboard/coaches/reject-data-access/reject-data-access.component";
import { Subscription } from "rxjs";
import { ChangePasswordComponent } from "src/app/dashboard/admins/change-password/change-password.component";
import { CoachRegisterComponent } from "src/app/coach-portal/coach-register/coach-register.component";
const document: any = window.document;

@Component({
  selector: "app-header",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.scss"],
})
export class HeaderComponent
  extends UnsubscribeOnDestroyAdapter
  implements OnInit, AfterViewInit, OnDestroy
{
  public config: any = {};
  isNavbarCollapsed = true;
  isNavbarShow: boolean;
  flagvalue;
  countryName;
  langStoreValue: string;
  isOpenSidebar: boolean;
  activeRoutName: string;
  currentUser: any;
  selectedBgColor = "white";
  isDarTheme = false;
  isDarkSidebar = false;
  currentUserRole: string;
  extendedMenuFlag: boolean = false;
  extendedMenuCoachFlag: boolean = false;
  permission: string;
  requestdata: any = [];
  hideAccessBlock: boolean = false;
  public tabName = "consumer";
  dataAccess: any;
  hideRegisterBtn: boolean = false;
  activeName: any;
  private subscription: Subscription;
  onlyReceivedOption: boolean = false;
  header: boolean = true;
  userLoginId: any;
  userDetails: any;

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private dialogModel: MatDialog,
    private renderer: Renderer2,
    public elementRef: ElementRef,
    private rightSidebarService: RightSidebarService,
    private configService: ConfigService,
    private authService: AuthService,
    public router: Router,
    public languageService: LanguageService,
    private notificationService: NotificationService,
    private profileService: ProfileService,
    private dashboardService: DashboardService,
    private spinner: NgxSpinnerService
  ) {
    super();
    this.activeRoutName = null;
    this.currentUserRole = sessionStorage.getItem("Role");
    this.currentUserRole = this.currentUserRole.toLocaleLowerCase();
    this.permission = sessionStorage.getItem("UserPermission");
    this.permission = this.permission?.toLocaleLowerCase();
    this.userLoginId = sessionStorage.getItem("UserId");
    if (this.permission !== "coach") {
      sessionStorage.setItem("coachmsg", "true");
    } else {
      sessionStorage.setItem("coachmsg", "false");
    }

    // data recive form view profile consumer approve/reject list
    this.subscription = this.dashboardService.backPage$.subscribe(
      (value: any) => {
        if (value !== null && value !== undefined && value !== "") {
          this.openDialogForApproveDataRequest();
        } else {
        }
      }
    );

    sessionStorage.setItem("mainHeader", "true");

    this.dashboardService.sendHeader("true");
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  ngOnInit() {
    this.activeRouterLink(this.router.url);
    this.activeName = "more";
    // show option of request
    if (this.currentUserRole === "consumer") {
      this.extendedMenuFlag = true;
    }

    // show option
    if (this.currentUserRole === "consumer" && this.permission === "coach") {
      this.extendedMenuCoachFlag = true;
    }
    this.config = this.configService.configData;

    this.langStoreValue = localStorage.getItem("lang");
    this.currentUser = JSON.parse(sessionStorage.getItem("CurrentUser"));
    this.profileService.userDetails$.subscribe({
      next: (res: any) => {
        this.currentUser = JSON.parse(sessionStorage.getItem("CurrentUser"));
      },
    });

    this.getCoachRequestForConsumer();

    // request access message method call.
    if (this.currentUserRole === "consumer" && this.permission !== "coach") {
      this.acceptCoachAccess();
    }

    // data recive form view profile consumer approve/reject list
    this.dashboardService.sendMsg$.subscribe((value) => {
      if (value !== null && value !== undefined && value !== "") {
        this.acceptCoachAccess();
      } else {
      }
    });

    this.dashboardService.dataSendToReceivedComp$.subscribe((value) => {
      if (value !== null && value !== undefined && value !== "") {
        this.hideRegisterBtn = true;
      } else {
      }
    });
    this.subscription = this.dashboardService.dataSend$.subscribe(
      (value: any) => {
        if (value !== null && value !== undefined && value !== "") {
          if (value === "respiratory") {
            this.activeName = value;
            this.onlyReceivedOption = true;
          }
          this.activeName = value;
        }
      }
    );

    this.dashboardService.faq$.subscribe((value: any) => {
      if (value !== null && value !== undefined && value !== "") {
        this.activeName = value;
      }
    });
  }

  ngAfterViewInit() {
    // set header color on startup
    if (localStorage.getItem("choose_skin")) {
      this.renderer.addClass(
        this.document.body,
        localStorage.getItem("choose_skin")
      );
      this.selectedBgColor = localStorage.getItem("choose_skin_active");
    } else {
      this.renderer.addClass(
        this.document.body,
        "theme-" + this.config.layout.theme_color
      );
      this.selectedBgColor = this.config.layout.theme_color;
    }

    if (localStorage.getItem("menuOption")) {
      if (localStorage.getItem("menuOption") === "menu_dark") {
        this.isDarkSidebar = true;
      } else if (localStorage.getItem("menuOption") === "menu_light") {
        this.isDarkSidebar = false;
      } else {
        this.isDarkSidebar =
          this.config.layout.sidebar.backgroundColor === "dark" ? true : false;
      }
    } else {
      this.isDarkSidebar =
        this.config.layout.sidebar.backgroundColor === "dark" ? true : false;
    }

    if (localStorage.getItem("theme")) {
      if (localStorage.getItem("theme") === "dark") {
        this.isDarTheme = true;
      } else if (localStorage.getItem("theme") === "light") {
        this.isDarTheme = false;
      } else {
        this.isDarTheme = this.config.layout.variant === "dark" ? true : false;
      }
    } else {
      this.isDarTheme = this.config.layout.variant === "dark" ? true : false;
    }
  }

  // callFullscreen
  callFullscreen() {
    if (
      !document.fullscreenElement &&
      !document.mozFullScreenElement &&
      !document.webkitFullscreenElement &&
      !document.msFullscreenElement
    ) {
      if (document.documentElement.requestFullscreen) {
        document.documentElement.requestFullscreen();
      } else if (document.documentElement.msRequestFullscreen) {
        document.documentElement.msRequestFullscreen();
      } else if (document.documentElement.mozRequestFullScreen) {
        document.documentElement.mozRequestFullScreen();
      } else if (document.documentElement.webkitRequestFullscreen) {
        document.documentElement.webkitRequestFullscreen();
      }
    } else {
      if (document.exitFullscreen) {
        document.exitFullscreen();
      } else if (document.msExitFullscreen) {
        document.msExitFullscreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if (document.webkitExitFullscreen) {
        document.webkitExitFullscreen();
      }
    }
  }

  // setLanguage
  setLanguage(text: string, lang: string, flag: string) {
    this.countryName = text;
    this.flagvalue = flag;
    this.langStoreValue = lang;
    this.languageService.setLanguage(lang);
  }

  // mobileMenuSidebarOpen
  mobileMenuSidebarOpen(event: any, className: string) {
    const hasClass = event.target.classList.contains(className);
    if (hasClass) {
      this.renderer.removeClass(this.document.body, className);
    } else {
      this.renderer.addClass(this.document.body, className);
    }
  }

  // callSidemenuCollapse
  callSidemenuCollapse() {
    const hasClass = this.document.body.classList.contains("side-closed");
    if (hasClass) {
      this.renderer.removeClass(this.document.body, "side-closed");
      this.renderer.removeClass(this.document.body, "submenu-closed");
    } else {
      this.renderer.addClass(this.document.body, "side-closed");
      this.renderer.addClass(this.document.body, "submenu-closed");
    }
  }

  // toggleRightSidebar
  public toggleRightSidebar(): void {
    this.subs.sink = this.rightSidebarService.sidebarState.subscribe(
      (isRunning) => {
        this.isOpenSidebar = isRunning;
      }
    );

    this.rightSidebarService.setRightSidebar(
      (this.isOpenSidebar = !this.isOpenSidebar)
    );
  }

  // logout
  logout() {
    this.authService.logout();
    sessionStorage.clear();
    localStorage.clear();
    this.router.navigate(["/authentication/login"]);
  }

  // activeRouterLink
  activeRouterLink(url) {
    this.router.navigate([url]);
    if (url.includes("respiratory/main")) this.activeRoutName = "respiratory";
    if (url.includes("device/main")) this.activeRoutName = "device";
    else if (url.includes("metabolic/main")) this.activeRoutName = "Metabolic";
    else if (url.includes("session/main")) this.activeRoutName = "Session";
    else if (url.includes("main")) this.activeRoutName = "Dashboard";
    else if (url.includes("more")) this.activeRoutName = "more";
    else if (url.includes("setting/main")) this.activeRoutName = "Settings";
  }

  // view profile
  openProfilePopup() {
    const dialogRef = this.dialogModel.open(ViewProfileComponent, {
      width: "800px",
      disableClose: true,
      maxHeight: "90vh",
    });
    dialogRef.afterClosed().subscribe({
      next: (res: any) => {
        if (res == 0) {
          this.notificationService.showNotification(
            "snackbar-success",
            "Profile updated successfully",
            "top",
            "center"
          );
        }
      },
    });
  }

  // openRegisterSectionModal
  openRegisterSectionModal(): void {
    event.stopPropagation();
    if (this.tabName == "consumer") {
      const dialogRef = this.dialogModel.open(CoachRegisterComponent, {
        width: "640px",
        panelClass: "dialog-container-custom",
        disableClose: true,
      });
      dialogRef.afterClosed().subscribe({
        next: (resp: any) => {
          if (resp == 1) {
          }
        },
      });
    }
  }

  // coach request for consumer
  getCoachRequestForConsumer() {
    var data = {
      Criteria: { ConsumerUserId: this.currentUser.ID },
      PageCriteria: {
        PageNumberToFetch: 0,
        PageSize: 10,
        SortOrder: "Desc",
        SortColumn: "CreatedDate",
      },
    };

    this.dashboardService.getCoachRequestForConsumer(data).subscribe({
      next: (res: any) => {
        if (!res.HasErrors) {
          for (let data of res.Data.Rows) {
            if (data.ApprovalStatus === "pending") {
              this.requestdata.push(data);
            }
          }
        } else {
          this.notificationService.showNotification(
            "snackbar-danger",
            res.Errors[0]?.Message,
            "top",
            "center"
          );
        }
      },
      error: (error) => {},
    });
  }

  // coach request for consumer
  acceptCoachAccess() {
    var data = {
      UserAccountId: this.currentUser.ID,
    };

    this.dashboardService.getListOfPendingCoachRequest(data).subscribe({
      next: (res: any) => {
        if (!res.HasErrors) {
          this.dataAccess = res.Data;
          this.hideAccessBlock = true;
          sessionStorage.setItem("extendedMenuFlag", "true");
          this.dashboardService.sendAlert("true");
        } else {
          this.hideAccessBlock = false;
          sessionStorage.setItem("extendedMenuFlag", "false");
          this.dashboardService.sendAlert("false");
        }
      },
      error: (error) => {},
    });
  }

  // open popup for give data access
  openDialogForApproveDataRequest() {
    const dialogRef = this.dialogModel.open(AddRequestModalComponent, {
      width: "640px",
      panelClass: "dialog-container-custom",
      disableClose: true,
      data: {
        UserId: this.dataAccess?.ConsumerUserId,
        ApprovalStatus: "Approved",
        Role: "coach",
        ID: this.dataAccess?.ID,
      },
    });
    dialogRef.afterClosed().subscribe({
      next: (resp: any) => {
        if (resp == 1) {
          this.acceptCoachAccess();
          sessionStorage.removeItem("extendedMenuFlag");
          sessionStorage.setItem("extendedMenuFlag", "false");
        }
      },
    });
  }

  // reject access of data
  rejectDataAccess() {
    const dialogRef = this.dialogModel.open(RejectDataAccessComponent, {
      width: "640px",
      panelClass: "dialog-container-custom",
      data: {
        CoachId: this.dataAccess.ID,
      },
      disableClose: true,
    });
    dialogRef.afterClosed().subscribe({
      next: (resp: any) => {
        if (resp == 1) {
          this.acceptCoachAccess();
        }
      },
    });
  }

  // ChangePasswordDialog
  openChangePasswordDialog() {
    let data = {
      Role: this.currentUserRole,
      ID: this.userLoginId,
      popupName: "Change Password",
      FirstName: this.currentUser?.FirstName,
      LastName: this.currentUser?.LastName,
    };

    const dialogRef = this.dialogModel.open(ChangePasswordComponent, {
      width: "640px",
      panelClass: "dialog-container-custom",
      disableClose: true,
      data: {
        data: data,
      },
    });
  }
}
