import { Validation } from "./../../../core/models/validation";
import { Component, Inject, OnInit } from "@angular/core";
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from "@angular/material/dialog";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { NgxSpinnerService } from "ngx-spinner";
import { NotificationService } from "../../../core/service/notification.service";
import { DashboardService } from "../../dashboard.service";
import Swal from "sweetalert2";
import { Router } from "@angular/router";
import { AuthService } from "@auth0/auth0-angular";
@Component({
  selector: "app-change-password",
  templateUrl: "./change-password.component.html",
  styleUrls: ["./change-password.component.sass"],
})
export class ChangePasswordComponent implements OnInit {
  resetPasswordForm: FormGroup;
  passwordPattern = this.dashboardService.PASSWORD_REGEXP;
  password = "password";
  hide: boolean = true;
  hides: boolean = true;
  getAddFormData = {
    newPassword: "",
    confirmPassword: "",
  };
  user: any;
  currentUserRole: string;
  role: any;
  popupName: any;
  intervaObj: any;

  constructor(
    private fb: FormBuilder,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<ChangePasswordComponent>,
    private spinner: NgxSpinnerService,
    private notificationService: NotificationService,
    public dashboardService: DashboardService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private authService: AuthService,
    public router: Router
  ) {
    this.currentUserRole = sessionStorage.getItem("Role")?.toLocaleLowerCase();
  }

  ngOnInit(): void {
    this.role =
      this.data?.data?.Role === "Consumer" ||
      this.data?.data?.Role === "consumer" ||
      this.data?.Role === "Consumer"
        ? "User"
        : "Admin";

    this.popupName =
      this.data?.data?.popupName === "Change Password"
        ? "Change Password"
        : "Reset Password";

    this.user = this.data;

    this.resetPasswordForm = this.fb.group(
      {
        newPassword: [
          "",
          [Validators.required, Validators.pattern(this.passwordPattern)],
        ],
        confirmPassword: [
          "",
          [Validators.required, Validators.pattern(this.passwordPattern)],
        ],
      },
      {
        validators: [Validation.match("newPassword", "confirmPassword")],
      }
    );
  }

  closeDialog(): void {
    this.dialog.closeAll();
  }

  onSubmitClick() {
    if (this.resetPasswordForm.valid) {
      this.getAddFormData.newPassword =
        this.resetPasswordForm.get("newPassword").value;
      this.getAddFormData.confirmPassword =
        this.resetPasswordForm.get("confirmPassword").value;

      Swal.fire({
        text: `Are you sure you want to reset password of ${
          this.data.data.FirstName
        } ${this.data.data.LastName || ""} ?`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#ef6f35",
        cancelButtonColor: "#fff",
        confirmButtonText: "Yes",
        allowOutsideClick: false,
      }).then((result) => {
        if (result.value) {
          var data = {
            Password: this.getAddFormData.newPassword,
            UserAccountId:
              this.data?.Tab == "Coach"
                ? this.data.data.ConsumerUserId
                : this.data.data.ID,
            Role: this.role,
          };

          this.spinner.show();
          this.dashboardService.resetPassword(data).subscribe({
            next: (res: any) => {
              if (!res.HasErrors) {
                this.spinner.hide();
                this.dialogRef.close(1);
                if (this.popupName !== "Change Password") {
                  this.notificationService.showNotification(
                    "snackbar-success",
                    "Password reset successfully",
                    "top",
                    "center"
                  );
                } else {
                  this.notificationService.showNotification(
                    "snackbar-success",
                    "Password change successfully",
                    "top",
                    "center"
                  );
                }

                // after change password user logout
                if (this.popupName == "Change Password") {
                  this.intervaObj = setInterval(() => {
                    this.logout();
                  }, 3000);
                }
              } else {
                this.notificationService.showNotification(
                  "snackbar-danger",
                  res.Errors[0].Message,
                  "top",
                  "center"
                );
                this.spinner.hide();
              }
            },
            error: (error) => {
              this.spinner.hide();
            },
          });
        }
      });
    }
  }

  logout() {
    this.authService.logout();
    sessionStorage.clear();
    localStorage.clear();
    this.router.navigate(["/authentication/login"]);
  }
}
