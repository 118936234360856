<div class="viewPopup popupHeader">
  <!-- popup header -->
  <div class="d-flex justify-content-between">
    <p class="popupHeading text-white" mat-dialog-title>My Profile</p>
    <button type="button" class="btn close text-white" aria-label="Close"></button>
  </div>

  <!-- form -->
  <form class="pt-4" [formGroup]="profileForm" (ngSubmit)="Save()">
    <div class="row">
      <div class="col-12 col-sm-6 col-md-6 col-lg-6 mb-0">
        <mat-form-field class="example-full-width mb-3" appearance="outline">
          <mat-label>First Name</mat-label>
          <input required matInput [minLength]="2" [maxLength]="100"
            (keypress)="dashboardService.ValidateFirstAlpha($event)" formControlName="firstName" />
          <mat-error *ngIf="g.firstName?.errors?.required">First Name is required</mat-error>
          <mat-error
            *ngIf="!g.firstName?.errors?.required && !g.firstName.errors?.maxLength && g.firstName.errors?.pattern">
            First Name is invalid
          </mat-error>
        </mat-form-field>
      </div>
      <div class="col-12 col-sm-6 col-md-6 col-lg-6 mb-0">
        <mat-form-field class="example-full-width mb-3" appearance="outline">
          <mat-label>Last Name</mat-label>
          <input matInput (keypress)="dashboardService.ValidateFirstAlpha($event)" [minLength]="2" [maxLength]="100"
            formControlName="lastName" />
          <mat-error *ngIf="g.lastName?.errors?.required">Last Name is required</mat-error>
          <mat-error
            *ngIf="!g.lastName?.errors?.required && !g.lastName.errors?.maxLength && g.lastName.errors?.pattern">Last
            Name is invalid
          </mat-error>
        </mat-form-field>
      </div>
    </div>
    <div class="row">
      <div class="col-12 col-sm-6 col-md-6 col-lg-6 mb-0">
        <mat-form-field class="example-full-width mb-3" appearance="outline">
          <mat-label>Email</mat-label>
          <input readonly required matInput formControlName="email" />
          <mat-error *ngIf="g.email?.errors?.required">Email is required</mat-error>
          <mat-error *ngIf="!g.email?.errors?.required && g?.email?.errors?.pattern">Email is invalid</mat-error>
        </mat-form-field>
      </div>

      <!-- it show only consumer role but not coach permission -->
      <div class="col-12 col-sm-6 col-md-6 col-lg-6 mb-0 d-flex">
        <div class="col-4 mb-0">
          <ng-select (change)="onCountryCodeChange($event.value)" formControlName="countryCode"
            class="ngSelectDropdownCss profileCountryCode" [items]="countriesCode" bindLabel="CountryCode"
            bindValue="CountryCode" placeholder="CountryCode">
          </ng-select>
          <mat-error *ngIf="submitted && g.countryCode?.errors?.required">Country Code is required</mat-error>

        </div>
        <div class="col-8 mb-0 ms-2">
          <mat-form-field class="example-full-width phoneInput" appearance="outline">
            <mat-label>Phone</mat-label>
            <input #phone (keyup)="onPhoneChange(phone.value)" matInput formControlName="phone" [minLength]="9"
              [maxLength]="16" (keypress)="dashboardService.ValidateNumeric($event)" [appOnlyNumberDirective]="true" />
            <mat-error *ngIf="submitted && g.phone?.errors?.required">Phone is required</mat-error>
            <mat-error *ngIf="submitted && !g.phone?.errors?.maxLength">Phone is invalid</mat-error>
          </mat-form-field>
        </div>
      </div>

    </div>

    <div class="col-12 col-lg-12 col-md-12 col-sm-12 mb-2 d-flex justify-content-end">
      <button type="submit" mat-raised-button color="primary" class="msr-3 text-white primaryBtn">
        <span class="saveBtn">Save</span>
      </button>
      <button type="button" mat-raised-button color="accent" class="msr-3" (click)="closeDialog()" mat-button>
        <span class="cancelBtn">Cancel</span>
      </button>
      <button [hidden]="currentUserRole ==='Admin'" type="button" mat-raised-button color="accent" mat-button
        (click)="openNewTab()">
        <span class="cancelBtn"> Delete Account</span>
      </button>
    </div>
  </form>

  <!-- coach list table -->
  <div *ngIf="hideBlock" [hidden]="currentUserRole==='admin' || permission === 'coach'">
    <p class="popupHeading popupHeader" mat-dialog-title class="text-white"> My Coaches</p>
    <div class="table-responsive" id="app-user-table">
      <mat-table #table [dataSource]="records" matSort class="mat-cell NewmatCell">
        <ng-container matColumnDef="firstName">
          <mat-header-cell *matHeaderCellDef mat-sort-header>Coach Name</mat-header-cell>
          <mat-cell *matCellDef="let row" class="text-capitalize text-center" matTooltipPosition="below"
            matTooltipClass="example-tooltip-bg" [matTooltip]="row.CoachName">
            {{ row.CoachName ? row.CoachName: '-' }}
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="email">
          <mat-header-cell *matHeaderCellDef mat-sort-header>Email
          </mat-header-cell>
          <mat-cell *matCellDef="let row" class="text-capitalize text-center" matTooltipPosition="below"
            matTooltipClass="example-tooltip-bg" [matTooltip]="row.CoachEmail">
            {{ row.CoachEmail == null ? '-' : (row.CoachEmail.length > 15)? (row.CoachEmail |
            slice:0:15)+'...':(row.CoachEmail )}}

          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="requestedOn">
          <mat-header-cell *matHeaderCellDef mat-sort-header>Modified Date</mat-header-cell>
          <mat-cell *matCellDef="let row" class="text-capitalize text-center" matTooltipPosition="below"
            matTooltipClass="example-tooltip-bg" [matTooltip]="row.UpdatedOn | date : 'dd/MM/yyyy'">
            {{row.UpdatedOn ? (row.UpdatedOn| date : 'dd/MM/yyyy'): '-'}}
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="status">
          <mat-header-cell *matHeaderCellDef mat-sort-header>Status</mat-header-cell>
          <mat-cell *matCellDef="let row" class="text-capitalize text-center" matTooltipPosition="below"
            matTooltipClass="example-tooltip-bg" [matTooltip]="row.ApprovalStatus">
            {{ row.ApprovalStatus ? row.ApprovalStatus: '-' }}
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="action">
          <mat-header-cell *matHeaderCellDef mat-sort-header class="admin-table-action-wrapper column-action">Action
          </mat-header-cell>
          <mat-cell *matCellDef="let row" class="text-capitalize text-center column-action" matTooltipPosition="left">
            <button *ngIf="row.ApprovalStatus === 'Pending'" (click)="
              openDialogForApproveRequest(
                'Approved',
                row.ConsumerUserId,
                row.ID
              )
            " mat-icon-button class="btn-tbl-schedule userFilter" title="Approve">
              <mat-icon class="iconFix">check</mat-icon>
            </button>

            <button *ngIf="row.ApprovalStatus === 'Approved'" (click)="
              openDialogForRevokeAccess(
                row.CoachName,
                row.CoachUserId
              )
            " title="Revoke access" mat-icon-button class="btn-tbl-schedule userFilter">
              <mat-icon class="actionButton">block</mat-icon>
            </button>

            <button (click)="
              openDialogForRejectRequest(
                'Rejected',
                row.ConsumerUserId,
                row.ID
              )
            " *ngIf="row.ApprovalStatus === 'Pending'" title="Reject" mat-icon-button
              class="btn-tbl-schedule userFilter">
              <mat-icon class="iconFix">cancel</mat-icon>
            </button>
          </mat-cell>
        </ng-container>
        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumns" [style.cursor]="'pointer'" matRipple></mat-row>
      </mat-table>
      <mat-paginator *ngIf="records?.data?.length > 3" [pageSize]="3" showFirstLastButtons="firstLastButtons">
      </mat-paginator>
    </div>
  </div>
</div>