import { Component, Inject, OnInit, ViewChild } from "@angular/core";
import { Constants } from "../../../config/constants";
import { NgxSpinnerService } from "ngx-spinner";
import { ThemePalette } from "@angular/material/core";
import {
  MatDialogModule,
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from "@angular/material/dialog";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { User } from "@auth0/auth0-angular";
import { ProfileService } from "../profile.service";
import { NotificationService } from "src/app/core/service/notification.service";
import { AngularFirePerformance } from "@angular/fire/compat/performance";
import { PageCriteria } from "../../models/pageCriteria";
import { DashboardService } from "src/app/dashboard/dashboard.service";
import Swal from "sweetalert2";
import { AddRequestModalComponent } from "src/app/coach-portal/add-request-modal/add-request-modal.component";
import { RejectDataAccessComponent } from "src/app/dashboard/coaches/reject-data-access/reject-data-access.component";
import { MatTableDataSource } from "@angular/material/table";
import { MatPaginator } from "@angular/material/paginator";
@Component({
  selector: "app-view-profile",
  templateUrl: "./view-profile.component.html",
  styleUrls: ["./view-profile.component.scss"],
})
export class ViewProfileComponent implements OnInit {
  profileForm: FormGroup;
  submitted = false;
  constants: any;
  emailPattern: string;
  userId: any;
  hide = true;
  color: ThemePalette = "accent";
  currentUser: any;
  editProfileTrace: any;
  countriesCode: any = [];
  displayedColumns = ["firstName", "email", "requestedOn", "status", "action"];
  pageCriteria: PageCriteria;
  type: string = "";
  currentUserRole: any;
  permission: any;
  hideBlock: boolean = true;
  showCountryCodeAndPhoneBlock: boolean = false;
  records = new MatTableDataSource<any>();
  CountryCode: any;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  constructor(
    private formBuilder: FormBuilder,
    private spinner: NgxSpinnerService,
    public dialog: MatDialogModule,
    public closedialog: MatDialog,
    private profileService: ProfileService,
    private notificationService: NotificationService,
    public dialogRef: MatDialogRef<ViewProfileComponent>,
    private performance: AngularFirePerformance,
    public dashboardService: DashboardService,
    private dialogModel: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.constants = Constants;
    this.emailPattern = this.constants.EMAIL_REGEXP;
    this.currentUser = new User();
    this.currentUser = JSON.parse(sessionStorage.getItem("CurrentUser"));
    this.pageCriteria = new PageCriteria();
    this.pageCriteria.SortColumn = "CreatedDate";
    this.currentUserRole = sessionStorage.getItem("Role");
    this.permission = sessionStorage.getItem("permission")?.toLocaleLowerCase();
    // this.permission = this.permission;
    this.userId = sessionStorage.getItem("UserId");

    // show option
    if (this.currentUserRole === "Consumer") {
      this.showCountryCodeAndPhoneBlock = true;
    }
  }

  get g() {
    return this.profileForm.controls;
  }

  async ngOnInit() {
    this.getCoachRequestForConsumer();
    this.getCountryCodeList();
    this.profileForm = this.formBuilder.group({
      firstName: [
        this.currentUser.FirstName,
        [
          Validators.required,
          Validators.pattern(this.constants.namesRegex),
          Validators.minLength(2),
          Validators.maxLength(100),
        ],
      ],
      lastName: [
        this.currentUser.LastName,
        [
          Validators.pattern(this.constants.namesRegex),
          Validators.minLength(2),
          Validators.maxLength(100),
        ],
      ],
      email: [
        this.currentUser.Email,
        [Validators.required, Validators.pattern(this.constants.EMAIL_REGEXP)],
      ],
      countryCode: [this.currentUser.CountryCode],
      phone: [
        this.currentUser.Phone,
        [
          Validators.pattern(this.constants.PHONE_NUMBER_REGEXP),
          Validators.minLength(9),
          Validators.maxLength(16),
        ],
      ],
    });
    this.editProfileTrace = await this.performance.trace("Edit_ProfileTrace");

    this.dashboardService.listCallTrigger$.subscribe((value) => {
      if (value !== null && value !== undefined && value !== "") {
        this.getCoachRequestForConsumer();
      }
    });
  }

  Save() {
    this.submitted = true;
    if (this.profileForm.value.phone) {
      this.profileForm.get("countryCode")?.setValidators(Validators.required);
      this.profileForm.get("countryCode")?.updateValueAndValidity();
    } else {
      this.profileForm.get("countryCode")?.setValidators(null);
      this.profileForm.get("countryCode")?.updateValueAndValidity();
    }

    if (this.profileForm.value.countryCode) {
      this.profileForm.get("phone")?.setValidators(Validators.required);
      this.profileForm.get("phone")?.updateValueAndValidity();
    }

    if (this.profileForm.valid) {
      this.spinner.show();
      let userDetails = {};
      let url: any;

      if (this.currentUserRole.toLocaleLowerCase() == "admin") {
        (userDetails = {
          FirstName: this.profileForm.get("firstName").value,
          LastName: this.profileForm.get("lastName").value,
          Phone: this.profileForm.get("phone").value,
          CountryCode: this.profileForm.get("countryCode").value,
          Email: this.currentUser.Email,
          ID: this.currentUser.ID,
          Status: "Active",
          IsActive: true,
        }),
          (url = "User/Admin/Update"); //admin update
      } else if (this.currentUserRole.toLocaleLowerCase() == "consumer") {
        (userDetails = {
          FirstName: this.profileForm.get("firstName").value,
          LastName: this.profileForm.get("lastName").value,
          Phone: this.profileForm.get("phone").value,
          Email: this.currentUser.Email,
          UserAccountId: this.userId,
          CountryCode: this.profileForm.get("countryCode").value,
        }),
          (url = "User/Update"); //consumer update
      }
      this.editProfileTrace.start();
      this.profileService.profileUpdate(userDetails, url).subscribe({
        next: (res: any) => {
          if (!res.HasErrors) {
            this.submitted = false;
            sessionStorage.setItem("CurrentUser", JSON.stringify(userDetails));
            this.profileService.userDetails$.next(userDetails);
            this.dashboardService.sendUpdateAdmin("true");
            this.dialogRef.close(0);
          } else {
            this.notificationService.showNotification(
              "snackbar-danger",
              res.Errors[0].Message,
              "top",
              "center"
            );
          }
          this.spinner.hide();
          this.editProfileTrace.stop();
        },
        error: () => {
          this.spinner.hide();
        },
      });
    }
  }
  closeDialog(): void {
    this.closedialog.closeAll();
  }

  // coach request for consumer
  getCoachRequestForConsumer() {
    this.spinner.show();
    var data = {
      PageCriteria: this.pageCriteria,
      UserAccountId: this.userId,
    };
    let records = [];
    this.dashboardService.GetAllPendingRequestToConsumer(data).subscribe({
      next: (res: any) => {
        if (!res.HasErrors) {
          this.records.data = res.Data.Result;
          records == this.records.data;
          this.records.paginator = this.paginator;
          this.spinner.hide();
        } else {
          this.hideBlock = false;
          this.spinner.hide();
        }
      },
      error: (error) => {},
    });
  }

  openDialogForRevokeAccess(CoachName: string, consumerUserId: number) {
    Swal.fire({
      text: `Are you sure you want to revoke access of ${CoachName} Coach ?`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#ef6f35",
      cancelButtonColor: "#fff",
      confirmButtonText: "Yes",
      allowOutsideClick: false,
    }).then((result) => {
      if (result.value) {
        this.type = "Rejected";
        this.revokeAccess(consumerUserId);
      }
    });
  }

  //dialog for approve request

  openDialogForApproveRequest(
    type: string,
    consumerUserId: number,
    ID: string
  ) {
    const dialogRef = this.dialogModel.open(AddRequestModalComponent, {
      width: "640px",
      panelClass: "dialog-container-custom",
      disableClose: true,
      data: {
        UserId: consumerUserId,
        ApprovalStatus: type,
        Role: "coach",
        ID: ID,
      },
    });
    dialogRef.afterClosed().subscribe({
      next: (resp: any) => {
        if (resp == 1) {
          this.dashboardService.accessRequestConumer("1");
          this.getCoachRequestForConsumer();
        }
      },
    });
  }

  //dialog for reject request
  openDialogForRejectRequest(type: string, consumerUserId: number, ID: string) {
    const dialogRef = this.dialogModel.open(RejectDataAccessComponent, {
      width: "640px",
      panelClass: "dialog-container-custom",
      data: {
        UserId: consumerUserId,
        ApprovalStatus: type,
        Role: "coach",
        CoachId: ID,
      },
      disableClose: true,
    });
    dialogRef.afterClosed().subscribe({
      next: (resp: any) => {
        if (resp == 1) {
          this.dashboardService.accessRequestConumer("1");
          this.getCoachRequestForConsumer();
        }
      },
    });
  }

  //revoke access
  revokeAccess(CoachUserId: any) {
    var data = {
      CoachId: CoachUserId,
    };
    this.spinner.show();
    this.dashboardService.revokeAccessByConsumer(data).subscribe({
      next: (res: any) => {
        if (!res.HasErrors) {
          this.spinner.hide();
          this.getCoachRequestForConsumer();
          this.notificationService.showNotification(
            "snackbar-success",
            "Access Revoke Successfully",
            "top",
            "center"
          );
        } else {
          this.spinner.hide();
          this.notificationService.showNotification(
            "snackbar-danger",
            res.Errors[0]?.Message,
            "top",
            "center"
          );
        }
      },
      error: (error) => {
        this.spinner.hide();
      },
    });
  }

  // get country code list
  getCountryCodeList() {
    this.spinner.show();
    var data = {};
    this.dashboardService.getCountryCodeList(data).subscribe({
      next: (res: any) => {
        if (!res.HasErrors) {
          this.countriesCode = res.Data.CountryCodeList;
          this.spinner.hide();
        } else {
          this.spinner.hide();
          this.notificationService.showNotification(
            "snackbar-danger",
            res.Errors[0]?.Message,
            "top",
            "center"
          );
        }
      },
    });
  }

  onPhoneChange(text: string) {
    if (text.length === 0) {
      this.profileForm.get("countryCode")?.setValidators(null);
      this.profileForm.get("countryCode")?.updateValueAndValidity();
    } else {
      this.profileForm.get("countryCode")?.setValidators(Validators.required);
      this.profileForm.get("countryCode")?.updateValueAndValidity();
    }

    if (text.length === 0) {
      this.profileForm.get("phone")?.setValidators(null);
      this.profileForm.get("phone")?.updateValueAndValidity();
    }
  }

  onCountryCodeChange(text: string) {
    if (this.profileForm.value.countryCode) {
      this.profileForm.get("phone")?.setValidators(Validators.required);
      this.profileForm.get("phone")?.updateValueAndValidity();
    } else {
      this.profileForm.get("phone")?.setValidators(null);
      this.profileForm.get("phone")?.updateValueAndValidity();
    }
  }

  openNewTab() {
    this.closedialog.closeAll();
    const data = { UserAccountId: this.userId, AccountDelete: true }; // Your data to be passed

    sessionStorage.setItem("data", JSON.stringify(data)); // Store data in session storage
    const url = "http://localhost:4200/#/user_account_delete"; // URL to open in new tab
    window.open(url, "_blank");
  }
}
