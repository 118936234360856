import { Component, Inject, OnInit } from "@angular/core";
import {
  FormBuilder,
  FormGroup,
  Validators,
} from "@angular/forms";
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from "@angular/material/dialog";
import { NgxSpinnerService } from "ngx-spinner";
import { Constants } from "src/app/config/constants";
import { NotificationService } from "src/app/core/service/notification.service";
import { DashboardService } from "src/app/dashboard/dashboard.service";
import Swal from "sweetalert2";

@Component({
  selector: "app-coach-register",
  templateUrl: "./coach-register.component.html",
  styleUrls: ["./coach-register.component.scss"],
})
export class CoachRegisterComponent implements OnInit {
  constants: any;
  CoachRegisterForm: FormGroup;
  sendSuccessResp: boolean;
  getRegisterFormData = {
    textmessage: "",
  };
  records: any[];
  user: any;
  userCoachId = sessionStorage.getItem("UserId");

  constructor(
    private fb: FormBuilder,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<CoachRegisterComponent>,
    private spinner: NgxSpinnerService,
    private notificationService: NotificationService,
    public dashboardService: DashboardService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.constants = Constants;
  }

  ngOnInit(): void {
    this.user = this.data;
    this.CoachRegisterForm = this.fb.group({
      textmessage: [
        "",
        [
          Validators.required,
          Validators.pattern(this.constants.EMPTY_STRING_REGEXP),
          Validators.minLength(2),
          Validators.maxLength(250),
        ],
      ],
    });
  }

  closeDialog(): void {
    this.dialog.closeAll();
  }

  onSubmitClick() {
    if (this.CoachRegisterForm.valid) {
      this.getRegisterFormData.textmessage =
        this.CoachRegisterForm.get("textmessage").value;
      var data = {
        UserId: this.userCoachId,
        Message: this.getRegisterFormData.textmessage,
      };
      this.spinner.show();
      this.dashboardService.RegisterAsCoachData(data).subscribe({
        next: (res: any) => {
          if (!res.HasErrors) {
            this.records = res.Rows;
            this.sendSuccessResp = res.HasErrors = false;
            this.spinner.hide();
            this.dialogRef.close(1);
            this.openApprovalCoach();
            this.dashboardService.sendSuccessResult("1");
          } else {
            this.notificationService.showNotification(
              "snackbar-danger",
              res.Errors[0].Message,
              "top",
              "center"
            );
            this.spinner.hide();
          }
        },
        error: (error) => {
          this.spinner.hide();
        },
      });
    }
  }

  openApprovalCoach() {
    this.dialog.closeAll();
    Swal.fire({
      text: "Your request has been successfully submitted. An email will be sent to you with further updates.",
      icon: "success",
      timer: 5000,
      timerProgressBar: true,
      showConfirmButton: false,
      allowOutsideClick: false,
    }).then((result) => {
      if (result.value) {
      }
    });
  }
}
