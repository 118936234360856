import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { AuthGuard } from "./core/guard/auth.guard";
import { AuthLayoutComponent } from "./layout/app-layout/auth-layout/auth-layout.component";
import { MainLayoutComponent } from "./layout/app-layout/main-layout/main-layout.component";
import { MemberAccountDeleteComponent } from "./member-account-delete/member-account-delete.component";

const routes: Routes = [
  {
    path: "",
    component: MainLayoutComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: "coach",
        loadChildren: () =>
          import("./coach-portal/coach-portal.module").then(
            (m) => m.CoachPortalModule
          ),
      },
      {
        path: "dashboard",
        loadChildren: () =>
          import("./dashboard/dashboard.module").then((m) => m.DashboardModule),
      },
      {
        path: "device",
        loadChildren: () =>
          import("./device/device.module").then((m) => m.DeviceModule),
      },
      {
        path: "respiratory",
        loadChildren: () =>
          import("./respiratory/respiratory.module").then(
            (m) => m.RespiratoryModule
          ),
      },
      {
        path: "metabolic",
        loadChildren: () =>
          import("./metabolic/metabolic.module").then((m) => m.MetabolicModule),
      },
      {
        path: "session",
        loadChildren: () =>
          import("./session/session.module").then((m) => m.SessionModule),
      },
      {
        path: "profile",
        loadChildren: () =>
          import("./shared/profile/profile.module").then(
            (m) => m.ProfileModule
          ),
      },
      {
        path: "setting",
        loadChildren: () =>
          import("./setting/setting.module").then((m) => m.SettingModule),
      },
    ],
  },
  {
    path: "authentication",
    component: AuthLayoutComponent,
    loadChildren: () =>
      import("./authentication/authentication.module").then(
        (m) => m.AuthenticationModule
      ),
  },
  { path: "", redirectTo: "/authentication/login", pathMatch: "full" },
  { path: "user_account_delete", component: MemberAccountDeleteComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
