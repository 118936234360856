<nav #navbar class="navbar pt-0 position-fixed">
  <div class="container-fluid d-sm-none">
    <!--  -->
    <div class="navbar-header nav-header d-sm-none">
      <a onClick="return false;" class="collapsed" (click)="isNavbarCollapsed = !isNavbarCollapsed"
        aria-expanded="false">
        <ul class="nav navbar-nav navbar-right">
          <li class="nav-item user_profile" ngbDropdown>
            <a onClick="return false;" ngbDropdownToggle role="button"
              class="nav-notification-icons pt-0 disable-after">
              <i class="fa-solid fa-user activeUser"></i>
            </a>
            <div ngbDropdownMenu class="notification-dropdown pullDown">
              <div class="noti-list userActionsDropdown">
                <ul class="menu">
                  <li class="body">
                    <ul class="user_dw_menu">
                      <li>
                        <a routerLink="profile/profile">
                          <mat-icon aria-hidden="false" class="msr-2">person</mat-icon>Profile
                        </a>
                      </li>
                      <li>
                        <a href="javascript:void(0);" (click)="logout()">
                          <mat-icon aria-hidden="false" class="msr-2">power_settings_new</mat-icon>Logout
                        </a>
                      </li>
                    </ul>
                  </li>
                </ul>
              </div>
            </div>
          </li>
        </ul>
      </a>
      <a href="#" onClick="return false;" class="bars" (click)="mobileMenuSidebarOpen($event, 'overlay-open')"></a>
      <a class="navbar-brand">
        <img src="/assets/images/image-gallery/full-logo.png" alt="" height="40" class="normalScreenLogo" />
        <img src="/assets/images/calibreLogo.svg" alt="" height="40" class="responsiveLogo" />
      </a>
    </div>
    <div class="collapse navbar-collapse" [ngbCollapse]="isNavbarCollapsed">
      <ul class="float-start collapse-menu-icon">
        <li>
          <button mat-icon-button (click)="callSidemenuCollapse()" class="sidemenu-collapse">
            <i class="nav-hdr-btn ti-align-left"></i>
          </button>
        </li>
      </ul>
      <ul class="nav navbar-nav navbar-right d-none d-sm-block">
        <li class="nav-item user_profile" ngbDropdown>
          <a href="#" onClick="return false;" ngbDropdownToggle role="button"
            class="nav-notification-icons pt-0 disable-after">
          </a>
          <div ngbDropdownMenu class="notification-dropdown pullDown">
            <div class="noti-list userActionsDropdown">
              <ul class="menu">
                <li class="body">
                  <ul class="user_dw_menu">
                    <li>
                      <a href="#" onClick="return false;">
                        <mat-icon aria-hidden="false" class="msr-2">person</mat-icon>Profile
                      </a>
                    </li>
                    <li>
                      <a href="#" onClick="return false;">
                        <mat-icon aria-hidden="false" class="msr-2">feedback</mat-icon>Feedback
                      </a>
                    </li>
                    <li>
                      <a href="#" onClick="return false;">
                        <mat-icon aria-hidden="false" class="msr-2">help</mat-icon>Help
                      </a>
                    </li>
                    <li>
                      <a href="javascript:void(0);" (click)="logout()">
                        <mat-icon aria-hidden="false" class="msr-2">power_settings_new</mat-icon>Logout
                      </a>
                    </li>
                  </ul>
                </li>
              </ul>
            </div>
          </div>
        </li>
      </ul>
    </div>
  </div>

  <!-- Extended Menu for consumer-->
  <div [ngClass]="[permission === 'coach'? 'display-none' : '']"
    *ngIf="currentUserRole === 'consumer' && hideRegisterBtn"
    class="container-fluid extend-row d-none d-sm-block nav-header">

    <div class="d-flex align-items-center justify-content-between">
      <ul class="d-flex menu-link-container">
        <li class="navbar-links px-3" (click)="openRegisterSectionModal()" routerLinkActive="active">
          Register As Coach
        </li>
      </ul>
      <ul class="mx-1 nav navbar-nav navbar-right d-none">
        <li class="nav-item user_profile" ngbDropdown>
          <a href="#" onClick="return false;" ngbDropdownToggle role="button"
            class="nav-notification-icons p-0 disable-after d-flex align-items-center justify-content-between text-decoration-none current-user-name">
            <div class="m-l-10 m-r-10">
              <p class="m-0 nameCSS">
                {{ currentUser.FirstName }}
              </p>
              <p class="m-0 text-white font-12">Welcome</p>
            </div>
            <mat-icon class="nameCSS">keyboard_arrow_down</mat-icon>
          </a>

          <div ngbDropdownMenu class="notification-dropdown pullDown">
            <div class="noti-list userActionsDropdown">
              <ul class="menu">
                <li class="body">
                  <ul class="user_dw_menu">
                    <li>
                      <a (click)="openProfilePopup()">
                        <mat-icon aria-hidden="false" class="msr-2">person</mat-icon>My Profile
                      </a>
                    </li>
                    <li>
                      <a (click)="openChangePasswordDialog()">
                        <mat-icon aria-hidden="false" class="msr-2">lock_outline</mat-icon>Change Password
                      </a>
                    </li>
                    <li>
                      <a href="javascript:void(0);" (click)="logout()">
                        <mat-icon aria-hidden="false" class="msr-2">power_settings_new</mat-icon>Logout
                      </a>
                    </li>
                  </ul>
                </li>
              </ul>
            </div>
          </div>
        </li>
      </ul>
    </div>
  </div>

  <!-- Main menu -->
  <div class="container-fluid d-none d-sm-block nav-header" *ngIf="header == true">
    <div class="d-flex align-items-center justify-content-between">
      <ul class="float-start collapse-menu-icon iconSec">
        <li>
          <a class="navbar-brand m-0" (click)="activeRouterLink('dashboard/main')">
            <img src="/assets/images/image-gallery/full-logo.png" alt="" height="40" class="normalScreenLogo" />
            <img src="/assets/images/calibreLogo.svg" alt="" height="40" class="responsiveLogo" />
          </a>
        </li>
      </ul>
      <ul class="d-flex menu-link-container headerNameSec">
        <li *ngIf="currentUserRole === 'admin'" class="navbar-links px-3 homeMenu" routerLink="dashboard/main"
          routerLinkActive="active">
          Home
        </li>

        <li *ngIf="currentUserRole === 'consumer'" class="navbar-links px-3" routerLink="dashboard/consumer"
          routerLinkActive="active">
          Home
        </li>

        <li *ngIf="currentUserRole === 'admin' && activeName !== 'coaches' && activeName !== 'admin'"
          class="navbar-links px-3" routerLink="dashboard/management/more" routerLinkActive="active">
          Users
        </li>
        <li *ngIf="currentUserRole === 'admin' && activeName == 'coaches'" class="navbar-links px-3"
          routerLink="dashboard/management/coaches" routerLinkActive="active">
          Users
        </li>
        <li *ngIf="currentUserRole === 'admin' && activeName=='admin'" class="navbar-links px-3"
          routerLink="dashboard/management/admin" routerLinkActive="active">
          Users
        </li>

        <li *ngIf="currentUserRole === 'admin'" class="navbar-links px-3" routerLink="device/main"
          routerLinkActive="active">
          Devices
        </li>

        <li *ngIf="currentUserRole === 'admin'" class="navbar-links px-3" routerLink="respiratory/main"
          routerLinkActive="active">
          Respiratory
        </li>

        <li *ngIf="currentUserRole === 'consumer'" class="navbar-links px-3" routerLink="coach/user-respiratory"
          routerLinkActive="active">
          Respiratory
        </li>

        <li *ngIf="currentUserRole === 'admin'" class="navbar-links px-3" routerLink="metabolic/main"
          routerLinkActive="active">
          Metabolic
        </li>

        <li *ngIf="currentUserRole === 'consumer'" class="navbar-links px-3" routerLink="coach/user-metabolic"
          routerLinkActive="active">
          Metabolic
        </li>

        <li *ngIf="currentUserRole === 'admin'" class="navbar-links px-3" routerLink="session/main"
          routerLinkActive="active">
          Sessions
        </li>

        <li *ngIf="currentUserRole === 'consumer'" class="navbar-links px-3" routerLink="coach/user-session"
          routerLinkActive="active">
          Sessions
        </li>

        <li [hidden]="currentUserRole === 'consumer'" *ngIf="!extendedMenuFlag" class="navbar-links px-3"
          routerLink="setting/main" routerLinkActive="active">
          Settings
        </li>

        <li *ngIf="extendedMenuCoachFlag" class="navbar-links px-3" routerLink="coach/myteam" routerLinkActive="active">
          My Team
        </li>

        <li [hidden]="currentUserRole === 'admin'"
          *ngIf="activeName !== 'Mobile Faq' && activeName !=='Privacy Policy' && activeName !== 'Terms Of Service'"
          class="navbar-links px-3" routerLink="setting/main/web-data-mgt" routerLinkActive="active">
          FAQ
        </li>

        <li [hidden]="currentUserRole === 'admin'" *ngIf="activeName == 'Mobile Faq'" class="navbar-links px-3"
          routerLink="setting/main/app-data-mgt" routerLinkActive="active">
          FAQ
        </li>

        <li [hidden]="currentUserRole === 'admin'" *ngIf="activeName =='Privacy Policy'" class="navbar-links px-3"
          routerLink="setting/main/privacy-policy" routerLinkActive="active">
          FAQ
        </li>

        <li [hidden]="currentUserRole === 'admin'" *ngIf="activeName == 'Terms Of Service'" class="navbar-links px-3"
          routerLink="setting/main/terms-service" routerLinkActive="active">
          FAQ
        </li>
      </ul>

      <ul class="mx-1 nav navbar-nav navbar-right">
        <li class="nav-item user_profile" ngbDropdown>
          <a href="#" onClick="return false;" ngbDropdownToggle role="button"
            class="nav-notification-icons p-0 disable-after d-flex align-items-center justify-content-between text-decoration-none current-user-name">
            <i class="fa-solid fa-user activeUser"></i>
            <div class="m-l-10 m-r-10">
              <p class="m-0 nameCSS">
                <span *ngIf="currentUserRole==='admin'">Admin</span>
                <span [hidden]="permission === 'coach'" *ngIf="currentUserRole==='consumer'">Member</span>
                <span *ngIf="currentUserRole==='consumer' && permission === 'coach'">Coach</span>
              </p>
              <p class="m-0 text-white font-12"> {{ currentUser.FirstName }}</p>
            </div>
            <mat-icon class="nameCSS">keyboard_arrow_down</mat-icon>
          </a>

          <div ngbDropdownMenu class="notification-dropdown pullDown">
            <div class="noti-list userActionsDropdown" [perfectScrollbar]>
              <ul class="menu">
                <li class="body">
                  <ul class="user_dw_menu">
                    <li>
                      <a (click)="openProfilePopup()">
                        <mat-icon aria-hidden="false" class="msr-2">person</mat-icon>My Profile
                      </a>
                    </li>
                    <li>
                      <a (click)="openChangePasswordDialog()">
                        <mat-icon aria-hidden="false" class="msr-2">lock_outline</mat-icon>Change Password
                      </a>
                    </li>
                    <li>
                      <a href="javascript:void(0);" (click)="logout()">
                        <mat-icon aria-hidden="false" class="msr-2">power_settings_new</mat-icon>Logout
                      </a>
                    </li>
                  </ul>
                </li>
              </ul>
            </div>
          </div>
        </li>
      </ul>
    </div>
  </div>

  <!-- request design - consumer accept coach request for data access -->
  <div *ngIf="extendedMenuFlag && hideAccessBlock" class="alert alert-success alert-dismissible fade show">
    <strong>{{dataAccess?.CoachName? (dataAccess.CoachName) : '-'}}</strong>
    <span class="ml-1">has requested access to your session data.</span>
    <div class="alert-button">
      <button (click)="openDialogForApproveDataRequest()" mat-raised-button mat-button color="primary"
        class="msr-3 text-white primaryBtn">
        <span class="saveBtn">Approve</span>
      </button>
      <button (click)="rejectDataAccess()" type="button" mat-raised-button mat-button color="accent">
        <span class="cancelBtn">Reject</span>
      </button>
    </div>
  </div>
</nav>